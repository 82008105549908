import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import Player from '@vimeo/player';
import Heading from './heading.js';

import Fetching from './../../functions/fetching.js';
import Colours from './../../assets/colours.js';
import Download from './../../assets/img/download.svg';
import PlayIcon from './../../assets/img/play-icon.svg';

class DownloadLink extends Component {

  static MIN_TIME_WATCHED_SECONDS = 30;
  static MIN_TIME_WATCHED_PERCENTAGE = 90;

  constructor(props) {
    super(props);

    this.state = {
      error: "",
			rdr: false,
			files: [],
			clientName: "",
			isLoading: true,
			activeFile:{},
			coverContentOpen:false,
			vimeoEmbedId: false,
            minTimeWatchedSecondsTriggered: false,
            minTimeWatchedPercentageTriggered: false
	};
	
	this.openVideoPlayer = this.openVideoPlayer.bind(this);
	this.hideLnk = this.hideLnk.bind(this);
    this.trackVideoEvent = this.trackVideoEvent.bind(this);
    this.handleSDKEvents = this.handleSDKEvents.bind(this)
  }

  // Fetch the list of users (paginated?!)
  componentDidMount () {

    // Check for pagination
		this.getDetails();

  }

  trackVideoEvent(fileId, eventType){
        var slug = this.props.match.params.slug;
		var token = this.props.match.params.token;

        var thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/files/track_event/' + slug + "/" + token + "/"+ fileId + "/"+ eventType, { credentials:"include" })
		.then(Fetching.statusCheck)
		.then(Fetching.jsonExtract)
		.then(function (data) {
		console.log('Request succeeded with JSON response', data);

		if (typeof data.error != 'undefined') {
			// Set the updated state
			thus.setState({ rdr: true });
		} else {
			console.log("Event tracked - " + eventType)
		}

		})
		.catch(function (error) {
		    console.log('Request failed', error);
		});

  }

  handleSDKEvents(fileId){
    this.trackVideoEvent(fileId, "opened-player")


    var iframe = document.querySelector('iframe');
    var player = new Player(iframe);

    let thus = this;

    player.on('play', function() {
        console.log('pressed play');
        thus.trackVideoEvent(fileId, "pressed-play")
    });

    player.on('timeupdate', (data) => {

        const percentageWatched = (data.seconds / data.duration) * 100;

        if(data.seconds >= DownloadLink.MIN_TIME_WATCHED_SECONDS && thus.state.minTimeWatchedSecondsTriggered == false){
            thus.trackVideoEvent(fileId, "watched-30s")
            thus.setState({minTimeWatchedSecondsTriggered:true})
        }

        if(percentageWatched >= DownloadLink.MIN_TIME_WATCHED_PERCENTAGE && thus.state.minTimeWatchedPercentageTriggered == false){
            thus.trackVideoEvent(fileId, "watched-90pc")
            thus.setState({minTimeWatchedPercentageTriggered:true})
        }
      });

    player.getVideoTitle().then(function(title) {
        console.log('title:', title);
    });
  }

  openVideoPlayer(e, fileId){

	  e.preventDefault();
		this.state.files.forEach((file) => {
			if(parseInt(file.id) === parseInt(fileId)){

				let newFile = {...file};
				var thus = this;

				let vimeoURLArray = newFile.vimeo_url.split('/');
				let vimeoID = vimeoURLArray[3];
                
				thus.setState({ vimeoEmbedId: vimeoID, coverContentOpen: true },() => {
                    thus.handleSDKEvents(fileId)
                })
                
			}
		})
  }


	getDetails () {

		// Get the URL data
		var slug = this.props.match.params.slug;
		var token = this.props.match.params.token;
		var mash = this.props.match.params.mash;

		// Retrieve the page data
		var thus = this;
		fetch(process.env.REACT_APP_API_BASE + '/customer_sends/details/' + slug + "/" + token + "/" + mash, { credentials:"include" })
		.then(Fetching.statusCheck)
		.then(Fetching.jsonExtract)
		.then(function (data) {
		console.log('Request succeeded with JSON response', data);

		if (typeof data.error != 'undefined') {
			// Set the updated state
			thus.setState({ rdr: true });
		} else {
			thus.setState({ clientName: data.clientName, files: data.files, isLoading: false });
		}

		})
		.catch(function (error) {
		    console.log('Request failed', error);
		});

	}

	hideLnk(e) {
		this.setState({coverContentOpen:false})
	}

  render() {

	  const { error, files, clientName, isLoading, vimeoEmbedId, rdr } = this.state;
		var slug = this.props.match.params.slug;
		var token = this.props.match.params.token;
		var mash = this.props.match.params.mash;

		var clName = clientName;
		if ( typeof this.props.match.params.isBatch != 'undefined' && this.props.match.params.isBatch === "y" ) {
			clName = "";
		}

    if (rdr) {
      return <Redirect to={"/download/fail"} />
    }

		var isBatch = "";
		if (typeof this.props.match.params.isBatch != "undefined" && this.props.match.params.isBatch === "y") {
			isBatch = "y";
		}

		let coverContent= null;

		if(this.state.coverContentOpen){
			let playerUrl = 'https://player.vimeo.com/video/' + vimeoEmbedId + '?transparent=1&playsinline=0&loop=1';
			coverContent = (<div className="cover">
				<a href="#close" onClick={(e) => this.hideLnk(e)}> </a>
				<div className="box vimeo-box">
					<div className="content vimeo-content">
						<iframe title="Embedded video" src={ playerUrl } frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
					</div>
				</div>
			</div>);
		}
		
		var content = <div style={ styles.container }>
								<p className="intro">Thank you for coming to download your files.</p>
								<p>You can access your files using the links below. Please bear in mind
								that this link will expire after 30 days, so if you wish to keep a copy
								of these files for your records you should store them offline.</p>
								<ul style={ styles.ul }>
									{ files.map( file => {

										let viewUrl = null
										if(file.is_video){
											// eslint-disable-next-line
											viewUrl = <a onClick={(e) => this.openVideoPlayer(e,file.id)}href='#' target="_blank" rel="noopener noreferrer" style={ styles.playBtn }>View Video</a>
										}
										return (
											<li key={ file.id } style={ styles.li }>
												{ file.name }
												{file.is_video ? (
													<a href={process.env.REACT_APP_API_BASE + '/files/download/' + file.id + '/' + slug + '/' + token + '/' + mash + "/" + isBatch} target="_blank" rel="noopener noreferrer" style={styles.downloadBtnGrey} download={file.name}>Download</a>
												) : (
													<a href={process.env.REACT_APP_API_BASE + '/files/download/' + file.id + '/' + slug + '/' + token + '/' + mash + "/" + isBatch} target="_blank" rel="noopener noreferrer" style={styles.downloadBtn} download={file.name}>Download</a>
												)}
												{viewUrl}
											</li>
										)
  									})}
								</ul>
							</div>;

		if (isLoading) {
    	content = <div className='width-1 centred'>
                    <div className="loader">Loading...</div>
                  </div>;
		}

    var errorContent = "";
    if (error !== "") {
      errorContent = <div className='error mbtm' style={ styles.full }>
                      { error }
                    </div>
    }

    return (
		
      <div className='width-6'>
		  {coverContent}
        <Heading mainTitle="Download your files" subTitle={ clName } />
		
        { errorContent }
        { content }

      </div>
    );
  }
}

var styles = {
	container: {
		background: Colours.bgWhite75,
		marginTop: 35,
		marginBottom: 125,
		padding: 25
	},
	ul: {
		marginLeft: 45,
		marginTop: 25
	},
	li: {
		margin: "12px 0px"
	},
	downloadBtn: {
		display: 'inline-block',
		background: Colours.grn + " url('" + Download + "') left 12px center / 14px auto no-repeat",
		color: Colours.txtWhite,
		fontSize: 14,
		padding: "3px 12px 3px 35px",
		marginLeft: 25,
		borderRadius: 20,
		textDecoration: 'none'
	},
	downloadBtnGrey: {
		display: 'inline-block',
		background: Colours.txtGrey + " url('" + Download + "') left 12px center / 14px auto no-repeat",
		color: Colours.txtWhite,
		fontSize: 14,
		padding: "3px 12px 3px 35px",
		marginLeft: 25,
		borderRadius: 20,
		textDecoration: 'none'
	},
	playBtn: {
		display: 'inline-block',
		background: Colours.grn + " url('" + PlayIcon + "') left 14px center / 12px auto no-repeat",
		color: Colours.txtWhite,
		fontSize: 14,
		padding: "3px 12px 3px 32px",
		marginLeft: 8,
		borderRadius: 20,
		textDecoration: 'none'
	}
};

export default DownloadLink;

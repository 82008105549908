import React, { Component } from 'react';

//import Download from './../../../../assets/img/download.svg';
import Email from './../../../../assets/img/email.svg';
import ProgressReviewIcon from './../../../../assets/img/doc-icon.svg';
import Colours from './../../../../assets/colours.js';
import ValuationSummary from './../valuation-summary.js';
import AgendaSummary from './../agenda-summary.js';
import FinancialPlanning from './../financial-planning.js'
import InternalDocument from '../internal-document';
import Objectives from '../objectives.js';

class Dropdown extends Component {

  constructor() {
    super();
    this.focusRef = React.createRef();
  }

  componentDidMount(){
    this.focusRef.current.focus();
  }


  render(){
    return (
      <div ref={this.focusRef} tabIndex="0" className={this.props.hasEmail ? "dropdown" : "dropdown push-lft"} onBlur={(e) => this.props.handleDropDownBlur(e)} >
        <ul className="spaced-list download-panel-progress-review">
	   	    <li className='download-title' key='progress-review-title'>Progress Review</li>
           <li style={styles.dashedBorder}>
           <li>
          <input type='checkbox' value={1} id='showInvestmentBreakdown' onChange={this.props.toggleShowInvestmentBreakdown} checked={this.props.showInvestmentBreakdown} />
            <label for='showInvestmentBreakdown' style={styles.percentageReturnText}>
              Show Investment Breakdown
            </label>
          </li>
           <input type='checkbox' value={1} id='showPercentageReturn' onChange={this.props.toggleShowPercentageReturn} checked={this.props.showPercentageReturn} />
            <label for='showPercentageReturn' style={styles.percentageReturnText}>
              Show Percentage Return
            </label>
            
          </li>
          
          {this.props.dropDownContent}
          
        </ul>
        <button onClick={(e) => this.props.handleGenerateProgressReviewClick(e)} style={{ ...styles.round, ...styles.download, ...styles.topBtn }}>Download</button>
        <div style={ styles.mtop }>
          <span  className='download-title' >Questionnaire Responses</span>
          <a
            href={ process.env.REACT_APP_API_BASE + "/admin/questionnaire_sections/generate_pdf/" + this.props.custRef + "/1/" + this.props.dateEntry + "--" + this.props.timeEntry }
            style={{ ...styles.round, ...styles.download }} target="_blank" rel="noopener noreferrer"
          >Download</a>
        </div>
        <ValuationSummary custRef={this.props.custRef} handleDownloadPDFSubMenuButtonClick={this.props.handleDownloadPDFSubMenuButtonClick} updateFiles={this.props.updateFiles }/>
        <AgendaSummary custRef={this.props.custRef} handleDownloadPDFSubMenuButtonClick={this.props.handleDownloadPDFSubMenuButtonClick} updateFiles={this.props.updateFiles } />
        <FinancialPlanning custRef={this.props.custRef} handleDownloadPDFSubMenuButtonClick={this.props.handleDownloadPDFSubMenuButtonClick} updateFiles={this.props.updateFiles} />
        <InternalDocument custRef={this.props.custRef} handleDownloadPDFSubMenuButtonClick={this.props.handleDownloadPDFSubMenuButtonClick} updateFiles={this.props.updateFiles} />
	    <Objectives custRef={this.props.custRef} handleDownloadPDFSubMenuButtonClick={this.props.handleDownloadPDFSubMenuButtonClick} updateFiles={this.props.updateFiles} />
        </div>
    );
  }

}

var styles = {
  form: {
    float: "right",
    marginRight: "1%",
    marginTop: "-5px",
    position:"relative"
  },
  label: {
    width: 85,
    display: "inline-block"
  },
  inputWrap: {
    display: "inline-block",
    width: 145
  },
  input: {
    display: 'inline-block',
    padding: "5px 12px",
    border: 0,
    boxShadow: '1px 1px 5px rgba(0,0,0,0.15)',
    fontSize: '14px',
    fontFamily: "'Mukta', sans-serif",
    width: 155,
    marginLeft: 5
  },
  time: {
    width: 70
  },
  wrapper: {
    display: 'inline-block',
    marginTop: 0
  },
  mtop: {
    paddingTop: 8
  },
  round: {
    borderRadius: 25,
    display: 'inline-block',
    margin: "0 0 0 18px",
    padding: "5px 12px",
    fontSize: 13,
		border: 'none'
  },
  red: {
    background: Colours.red,
    margin: "0 0 0 5px"
  },
  download: {
    background: Colours.txtGrey,
    color: Colours.txtWhite,
    textDecoration: 'none',
    float:'right',
    marginLeft:0,
    fontSize:'12px',
    fontWeight:'normal',
    padding: "3px 8px"
  },
	progressReview: {
	    marginLeft: 10,
	    background: Colours.txtGrey + " url('" + ProgressReviewIcon + "') left 16px center / 12px auto no-repeat",
	    color: Colours.txtWhite,
	    textDecoration: 'none',
	    paddingRight: 15,
	    paddingLeft: 38,
			cursor: 'pointer'
  },
	email: {
		marginLeft: 10,
    background: Colours.txtGrey + " url('" + Email + "') left 13px center / 17px auto no-repeat",
    color: Colours.txtWhite,
    textDecoration: 'none',
		paddingRight: 15,
    paddingLeft: 38
  },
  topBtn: {
    marginTop: 8
  },
  dashedBorder:{
    borderBottom:"1px dashed "  + Colours.txtGrey,
    paddingRight:5
  },
  percentageReturnText:{
    fontStyle:'italic',
    paddingLeft:"5px"
  }
};

export default Dropdown;

import React, { Component, Fragment } from 'react';
import Colours from './../../../assets/colours.js';
import Fetching from './../../../functions/fetching.js';
import Btn from './../../common/btn.js';

import Upload from './upload.js';

class Files extends Component {

  constructor(props) {
    super(props);

    this.state = {
      uid: (typeof this.props.custRef != 'undefined') ? this.props.custRef : "-",
      files: [],
	  pageNo: 1,
      isLightboxVisible:false,
      fileHistory:[],
      uploadDate:"",
      lightboxView:false,
      videoViewHistory:[]
    };
  	this.loadFiles = this.loadFiles.bind(this);
    this.toggleCover= this.toggleCover.bind(this);
    this.loadFileHistory = this.loadFileHistory.bind(this);
    this.loadVideoViewHistory = this.loadVideoViewHistory.bind(this)
	}

  // Fetch the user data
  componentDidMount () {
	this.loadFiles();
	}

	componentWillReceiveProps (newProps) {
		if (this.props.lastFileUpdate !== newProps.lastFileUpdate) {
			this.loadFiles();
		}
	}

    toggleCover(lightboxView){
        this.setState({isLightboxVisible:!this.state.isLightboxVisible, lightboxView})
    }

	loadFiles () {

		if(typeof this.props.isCommunication !== 'undefined' && this.props.isCommunication === true ){
			
			let attachments = (typeof this.props.attachments !== 'undefined' && this.props.attachments.length > 0) ? this.props.attachments : [];
			this.setState({ files: attachments});
		} else {
			var thus = this;
			let { pageNo, uid } = this.state;

			fetch(process.env.REACT_APP_API_BASE + '/admin/files/list/' + uid + "/" + pageNo, { credentials:"include" })
			.then(Fetching.statusCheck)
			.then(Fetching.jsonExtract)
			.then(
				function (data) {

				// Check whether we have a failure
				if (typeof data.error == 'undefined') {
					// Redirect to the error page
					thus.setState({ files: data });

				} else {
					// Process the data for display of the tabs
					thus.setState({ files: [] });
				}
				}
			);
		}
    	

	}

    loadFileHistory (e, fileId) {

        var thus = this;

        this.setState({fileHistory:[]})
        // let { pageNo, uid } = this.state;

        fetch(process.env.REACT_APP_API_BASE + '/admin/files/list_history/' + fileId +'/0', { credentials:"include" })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(
            function (data) {

                var fileHistory = []
                for(var i =0; i<data.length; i++){
                    fileHistory.push(data[i].downloadDate)
                }

                var uploadDate = '';
                if(data.length > 0){
                    uploadDate = data[0].createdFormatted
                }
                
                thus.setState({fileHistory:fileHistory, uploadDate:uploadDate})
                thus.toggleCover("file-history")
  
            }
        );

    	

	}

    loadVideoViewHistory (e, fileId) {

        var thus = this;

        this.setState({videoViewHistory:[]})
        // let { pageNo, uid } = this.state;

        let customerRef = '';

        if(this.state.uid == '-'){
            customerRef = 0;
        } else {
            customerRef = this.state.uid
        }

        fetch(process.env.REACT_APP_API_BASE + '/admin/files/list_history/' + fileId +'/1/'+ customerRef, { credentials:"include" })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(
            function (data) {

                var uploadDate = '';
                if(data.length > 0){
                    uploadDate = data[0].createdFormatted
                }
                thus.setState({videoViewHistory:data,uploadDate})
                thus.toggleCover("video-view")  
            }
        );

    	

	}

	deleteFile ( e, fileId ) {

		if (window.confirm("Are you sure you wish to delete this file?")) {

			if(typeof this.props.isCommunication !== 'undefined' && this.props.isCommunication === true ){
				this.props.removeAttachment( fileId);
			} else {
				var thus = this;
				fetch(process.env.REACT_APP_API_BASE + '/admin/files/delete/' + fileId, { credentials:"include" })
				.then(Fetching.statusCheck)
				.then(Fetching.jsonExtract)
				.then(
					function (data) {

					// Check whether we have a failure
					if (typeof data.error == 'undefined') {
						// Redirect to the error page
						thus.loadFiles();
					} else {
						// Process the data for display of the tabs
						alert("Sorry but there was an error deleting the file. Please try again.");
					}
					}
				);
			}

			

		}

	}

  render() {

    const { files, isLightboxVisible,lightboxView } = this.state;
	let blockStyle = {...styles.block};

	if(this.props.align !== undefined && this.props.align === 'right'){
		blockStyle.float = 'right';
	}

    var coverContent = "";

    if ( isLightboxVisible !== false) {
        switch(lightboxView){
            case "file-history":
                coverContent = (
                    <div className="cover">
                        <a href="#close" onClick={ (e) => this.toggleCover(false) }> </a>
                        <div className="box">
                            <h5>File History</h5>
                            {this.state.fileHistory.length > 0 ? (
                                <Fragment>
                                    <p style={styles.uploadDate}>Uploaded: {this.state.uploadDate}</p>
                                    <ul style={styles.downloadList}>
                                    {this.state.fileHistory.map((fileDownload,i) => (
                                        <li key={'download-'+i}>Downloaded: {fileDownload}</li>
                                    ))}
                                    </ul>
                                </Fragment>
                            ) : (
                                <p>No Downloads</p>
                            )}
                        </div>
                    </div>)
                break;
            case "video-view":

                let content = '';

                if(this.state.uid !== '-'){
                    if(this.state.videoViewHistory.length > 0){
                        content = (
                            <Fragment>
                                <p style={styles.uploadDate}>Uploaded: {this.state.uploadDate}</p>
                                <ul style={styles.downloadList}>
                                    {this.state.videoViewHistory.map((videoViewItem,i) => (
                                        <li key={'download-'+i}>{videoViewItem.eventType+ ' - '+videoViewItem.downloadDate}</li>
                                    ))}
                                </ul>
                            </Fragment>
                        )
                    } else {
                        content = <p>No Events</p> 
                    }
                    
                } else {
                    if(this.state.videoViewHistory.length > 0){
                        content = (
                            <Fragment>
                                <p style={styles.uploadDate}>Uploaded: {this.state.uploadDate}</p>
                                
                                    {this.state.videoViewHistory.map((videoViewItem,i) => (
                                        <Fragment key={'download-'+i}>
                                            <h6 style={{fontSize:16}}>{videoViewItem.customerName}</h6>
                                            <ul style={styles.downloadList}>
                                                {videoViewItem.viewHistory.map((viewHistoryItem,j) => (
                                                    <li key={'item-'+j}>{viewHistoryItem.eventType+ ' - '+viewHistoryItem.downloadDate}</li>
                                                ))}
                                            </ul>
                                        </Fragment>
                                    ))}
                            </Fragment>
                        )
                    } else {
                        content = <p>No Events</p> 
                    }
                }
                coverContent = (
                    <div className="cover">
                        <a href="#close" onClick={ (e) => this.toggleCover(false) }> </a>
                        <div className="box">
                            <h5>Video View History</h5>
                            {content}                            
                        </div>
                    </div>)
                break
            default:
                coverContent = ''
        }
      
    }

	let upload;
	if(typeof this.props.isCommunication !== 'undefined' && this.props.isCommunication === true ){
		upload =  <Upload isCommunication={true} custRef={ this.props.custRef } callbackFunc={ this.props.addAttachment } />
	} else {
		upload = <Upload custRef={ this.props.custRef } callbackFunc={ () => this.loadFiles() } />
	}

    return (
        <Fragment>
            {coverContent}
        
      <div className={ typeof this.props.title != 'undefined' ? "width-4" : "" } style={ blockStyle }>
				{ typeof this.props.title != 'undefined' ? (
					<h2>
						{ this.props.genLnk === true ? <Btn title="Cancel link" type="link" lnk={ "/admin/clients/cancel-link/" + this.props.custRef } wrapStyle={ styles.wrapper } btnStyle={{ ...styles.round, ...styles.grey,...styles.margin }} /> : "" }
						{ this.props.genLnk === true ? <Btn title="Generate link" type="link" lnk={ "/admin/clients/file-link/" + this.props.custRef } wrapStyle={ styles.wrapper } btnStyle={{ ...styles.round, ...styles.grey }} /> : "" }
						{ this.props.title }
					</h2>
				) : "" }
				{ typeof this.props.isCommunication !== 'undefined' && this.props.isCommunication === true ? (
					<h4>Attachments</h4>

				) : "" }

				<ul style={ styles.ul }>
	        		{ files.map( file =>
						<li style={{clear:'both'}} key={ file.id }>
							{typeof file.is_audio !== 'undefined' && file.is_audio ? (
								<div className='audio-player-container'>
									<audio
										className='communication-audio-player'
										controls
										src={ process.env.REACT_APP_API_BASE + "/admin/files/download/" + file.id }>
											Not supported
									</audio>
									<span className='right' style={ styles.sml }>
                                        {file.latestDownload !== null ? (
                                            <Fragment>
                                                Downloaded: <a href='' style={ styles.a } onClick={(e) => this.loadFileHistory(e, file.id)}>{ file.latestDownload }</a> (<a href="#delete" style={ styles.a } onClick={ (e) => this.deleteFile(e, file.id) }>Delete</a>)
                                            </Fragment>
                                            ) : (
                                                <Fragment>Uploaded: { file.createdFormatted } (<a href="#delete" style={ styles.a } onClick={ (e) => this.deleteFile(e, file.id) }>Delete</a>)</Fragment>
                                            )
                                        }
                                    </span>
								</div>) : 
								(<span className='right' style={ styles.sml }>
                                    {file.latestDownload !== null ? (
                                        <Fragment>
                                            {file.is_video == 1 ? (
                                                <Fragment>
                                                    Last Viewed <a onClick={(e) => this.loadVideoViewHistory(e,file.id)} href='#' style={ styles.a }>{ file.latestDownload }</a> (<a href="#delete" style={ styles.a } onClick={ (e) => this.deleteFile(e, file.id) }>Delete</a>)
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    Downloaded: <a href='#' style={ styles.a } onClick={(e) => this.loadFileHistory(e, file.id)}>{ file.latestDownload }</a> (<a href="#delete" style={ styles.a } onClick={ (e) => this.deleteFile(e, file.id) }>Delete</a>)
                                                </Fragment>
                                            )
                                            }
                                        </Fragment>
                                        ) : (
                                            <Fragment>Uploaded: { file.createdFormatted } (<a href="#delete" style={ styles.a } onClick={ (e) => this.deleteFile(e, file.id) }>Delete</a>)</Fragment>
                                        )
                                    }
                                </span>)
							}
							
							<a href={ process.env.REACT_APP_API_BASE + "/admin/files/download/" + file.id } target="_blank" rel="noopener noreferrer" style={ styles.a }>{ file.file_name }</a>
							
						</li>
					) }

					{upload}
				</ul>
      </div>
      </Fragment>
    );

  }
}

var styles = {
	block: {
		marginBottom: 25,
	    background: Colours.bgGrey,
	    padding: "12px 20px",
	    boxSizing: "border-box",
	    overflow: "auto"
	},
	sml: {
		opacity: '0.5',
		fontSize: 14,
		paddingTop: 2
	},
	ul: {
		listStyle: 'none'
	},
	a: {
		color: Colours.txtBlack
	},
	wrapper: {
		float: "right",
		marginTop: 0
	},
	round: {
		marginTop: 0,
		borderRadius: 25
	},
	grey: {
		backgroundColor: Colours.txtGrey,
		fontSize: 14
	},
	margin: {
		marginLeft:4
	},
    uploadDate:{
        textAlign:'left',
        color: Colours.grn
    },
    downloadList:{
        paddingLeft:20
    }
};


export default Files;

import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

import CustomersList from './list.js';
import CustomerCreate from './create.js';
import CustomerDelete from './delete.js';
import CustomerDetails from './details.js';
// import CustomerPlanning from './planning.js';
// import MapDetails from './map-details.js';
import SectionsLink from './sections-link.js';
import CustomField from './../custom-fields/index.js';
import FileLink from './file-link.js';
// import PastTransactionInput from './planning-components/past-transaction-input.js';
import CancelLink from './cancel-link.js';
import TransactInvestments from './transact-investments.js';
import Presentation from './presentation.js';

class Customers extends Component {
  render() {

    return (
      <div className='content'>
        <Switch>
          <Route exact path='/admin/clients' component={ CustomersList }/>
          <Route exact path='/admin/clients/create' component={ CustomerCreate }/>
          <Route path='/admin/clients/generate/:custRef' component={ SectionsLink }/>
          <Route exact path='/admin/clients/generate' component={ SectionsLink }/>
		      <Route path='/admin/clients/file-link/:custRef' component={ FileLink }/>
          <Route path='/admin/clients/cancel-link/:custRef' component={ CancelLink }/>
          {/* <Route path='/admin/clients/transact/:custRef' component={ TransactInvestments }/> */}
          <Route path='/admin/clients/delete/:custRef' component={ CustomerDelete }/>
          {/* <Route path='/admin/clients/planning/:custRef' component={ CustomerPlanning }/>
          <Route path='/admin/clients/planningTransactions/:custRef' component={ PastTransactionInput }/> */}
          <Route path='/admin/clients/details/:custRef/:sxnSlug' component={ CustomerDetails }/>
          <Route path='/admin/clients/details/:custRef' component={ CustomerDetails }/>
          {/* <Route path='/admin/clients/mindmap/:custRef/:sxnSlug' component={ MapDetails }/>
          <Route path='/admin/clients/mindmap/:custRef' component={ MapDetails }/> */}
          <Route path='/admin/clients/presentation/:custRef/:presentationSlug' component={ Presentation }/>
          <Route path='/admin/clients/presentation/:custRef' component={ Presentation }/>
          
          <Route path='/admin/clients/:filterParams' component={ CustomersList }/>
          <Route path='/admin/custom-fields' component={ CustomField }/>
        </Switch>
      </div>
    );
  }
}

export default Customers;

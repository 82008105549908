import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import Btn from './../../common/btn.js';
import Colours from './../../../assets/colours.js';

import Fetching from "./../../../functions/fetching.js";

import DatePicker from "react-datepicker";
import "./../../../assets/css/datepicker.css";

import Download from './../../../assets/img/download.svg';
import Email from './../../../assets/img/email.svg';
import ProgressReviewIcon from './../../../assets/img/doc-icon.svg';

import ProgressReview from './progress-review.js'
// import CashCalcSync from './cashcalc-sync.js'
//import ValuationSummary from './valuation-summary.js'


class TimeMachine extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dateEntry: "",
      timeEntry: "",
	  haveEmail: false,
    };

    this.handleClear = this.handleClear.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleUpdateTime = this.handleUpdateTime.bind(this);
    this.formatDate = this.formatDate.bind(this);
  }

	componentWillMount () {
		this.getCustomer();
	}

	getCustomer () {
		if (typeof this.props.custRef != 'undefined') {

			let thus = this;
			fetch(process.env.REACT_APP_API_BASE + '/admin/customers/name_details/' + this.props.custRef, { credentials:"include" })
				.then(Fetching.statusCheck)
				.then(Fetching.jsonExtract)
				.then(
					function (data) {
						// Check whether we have a failure
						if (typeof data.data[0] != 'undefined' && data.data[0].email) {

							thus.setState({ haveEmail: true });

						}
					}
				);

		}

	}

  handleClear (e) {
    this.props.clearTimeMachine(e);
    this.setState({ timeEntry: "", dateEntry: "" });
  }

  formatDate ( date ) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  handleUpdate (e) {
    let upDate = this.formatDate(e);
    this.props.updateDate(upDate);
    this.setState({ dateEntry: upDate });
  }

  handleUpdateTime (e) {
    this.props.updateTime(e);
    this.setState({ timeEntry: e.target.value });
  }


  render() {

    let { timeEntry, dateEntry, haveEmail} = this.state;

    var exportBtns = "";
    if (typeof this.props.hideExport == 'undefined' || !this.props.hideExport) {

			if (haveEmail) {

	      exportBtns = <Fragment>

            <ProgressReview
              updateFiles={this.props.updateFiles}
              custRef={this.props.custRef}
              dateEntry={dateEntry}
              timeEntry={timeEntry}
              hasEmail={ true }
              pdfGenerating ={this.props.pdfGenerating}
              togglePDFGeneration={this.props.togglePDFGeneration}
              renderedFromTimeMachine={true}
            />
            {/* <CashCalcSync custRef={this.props.custRef} loadTab={this.props.loadTab } /> */}
						<Link to={ "/admin/emails/1/" + this.props.custRef } style={{ ...styles.round, ...styles.email }}>Communications</Link>
            {/* <Link style={ {...styles.round, ...styles.investment} } to={ `/admin/clients/transact/` + this.props.custRef}>Investments</Link> */}
          </Fragment>

			} else {

				exportBtns = <Fragment>


            <ProgressReview
              updateFiles={this.props.updateFiles}
              custRef={this.props.custRef}
              dateEntry={dateEntry}
              timeEntry={timeEntry}
              hasEmail={ false }
              pdfGenerating ={this.props.pdfGenerating}
              togglePDFGeneration={this.props.togglePDFGeneration}
            />
            {/* <CashCalcSync custRef={this.props.custRef} loadTab={this.props.loadTab} /> */}
						<Link to={ "/admin/emails/1/" + this.props.custRef } style={{ ...styles.round, ...styles.email }}>Communications</Link>
            {/* <Link style={ {...styles.round, ...styles.investment} } to={ `/admin/clients/transact/` + this.props.custRef}>Investments</Link> */}
          </Fragment>

			}
    }

    var datePick = <DatePicker
        selected={ new Date() }
        onChange={ (e) => this.handleUpdate(e) }
        dateFormat="d/M/yyyy"
        style={ styles.input }
      />;
    if (dateEntry !== "") {
      datePick = <DatePicker
        selected={ new Date(dateEntry) }
        onChange={ (e) => this.handleUpdate(e) }
        dateFormat="d/M/yyyy"
        style={ styles.input }
      />
    }

    return (
      <div style={ styles.form } className="tabHide">
        <label style={ styles.label }>View as of:</label>
        <div style={ styles.inputWrap }>
          { datePick }
        </div>
        <input type="time" style={{ ...styles.input, ...styles.time }} id="timeMachineTime" value={ timeEntry } onChange={ (e) => this.handleUpdateTime(e) } />

        <Btn type="button" btnStyle={ styles.wrapper } btnStyleInner={ styles.round } title="View History" handleSubmission={ (e) => this.props.confirmTimeMachine(e) } />
        <Btn type="button" btnStyle={ styles.wrapper } btnStyleInner={{ ...styles.round, ...styles.red }} title="Clear" handleSubmission={ (e) => this.handleClear(e) } />

				{ exportBtns }

      </div>
    );

  }
}

var styles = {
  form: {
    float: "right",
    marginRight: "1%",
    marginTop: "-5px",
    position:"relative"
  },
  label: {
    width: 85,
    display: "inline-block"
  },
  inputWrap: {
    display: "inline-block",
    width: 145
  },
  input: {
    display: 'inline-block',
    padding: "5px 12px",
    border: 0,
    boxShadow: '1px 1px 5px rgba(0,0,0,0.15)',
    fontSize: '14px',
    fontFamily: "'Mukta', sans-serif",
    width: 155,
    marginLeft: 5
  },
  time: {
    width: 70
  },
  wrapper: {
    display: 'inline-block',
    marginTop: 0
  },
  round: {
    borderRadius: 25,
    display: 'inline-block',
    margin: "0 0 0 18px",
    padding: "5px 12px",
    fontSize: 13,
		border: 'none'
  },
  red: {
    background: Colours.red,
    margin: "0 0 0 5px"
  },
  download: {
    marginLeft: 45,
    background: Colours.txtGrey + " url('" + Download + "') right 12px center / 16px auto no-repeat",
    color: Colours.txtWhite,
    textDecoration: 'none',
    paddingRight: 42,
    paddingLeft: 15,
  },
	progressReview: {
    marginLeft: 10,
    background: Colours.txtGrey + " url('" + ProgressReviewIcon + "') left 16px center / 12px auto no-repeat",
    color: Colours.txtWhite,
    textDecoration: 'none',
    paddingRight: 15,
    paddingLeft: 38,
		cursor: 'pointer'
  },
	email: {
		marginLeft: 10,
    background: Colours.txtGrey + " url('" + Email + "') left 13px center / 17px auto no-repeat",
    color: Colours.txtWhite,
    textDecoration: 'none',
		paddingRight: 15,
    paddingLeft: 38,
    fontWeight:400
	},
  investment: {
		marginLeft: 10,
    background: Colours.txtGrey ,
    color: Colours.txtWhite,
    textDecoration: 'none',
		paddingRight: 15,
    paddingLeft: 15,
    fontWeight:400
	}
};

export default TimeMachine;

import React, { Component } from 'react';

import { connect } from 'react-redux';


import "./../../../assets/css/datepicker.css";


import Fetching from '../../../functions/fetching.js';

import { Link } from 'react-router-dom';

class PresentationSectionPanel extends Component {

  // Constructor to set up data and set init state
  constructor(props) {
    super(props);

    this.state = {
    
      custRef: "",

      createNew: false,

      minimised: true,

    };

    this.getTokens = this.getTokens.bind(this);

  }

  componentWillMount () {

    this.getTokens();
  }



  getTokens () {
    fetch(process.env.REACT_APP_API_BASE + '/users/tokens', { method: 'get', credentials:"include" })
      .then(Fetching.statusCheck)
      .then(Fetching.jsonExtract)
      .then(data => this.props.dispatch({ type: 'SET_TOKENS', token: data.token, tokenKey: data.tokenKey, sessionId: data.session }))
      .catch(function (error) {
        console.log('Request failed', error);
      });
  }

  formatDate ( date ) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  render() {


      return (

          <div className="agenda-block progress-review-presentation width-12 ">

						<div id="handle">

	         

              <ul className='section-list'>
              <li className={this.props.presentationSlug ==='agenda' ? 'active-presentation-panel' : ''}><Link to ={'agenda'}>Agenda</Link></li>
                <li className={this.props.presentationSlug ==='objectives' ? 'active-presentation-panel' : ''}><Link to ={'objectives'}>Objectives</Link></li>
                <li className={this.props.presentationSlug ==='valuation_summary' ? 'active-presentation-panel' : ''}><Link to ={'valuation_summary'}>Valuation Summary</Link></li>
                <li className={this.props.presentationSlug ==='recommendations' ? 'active-presentation-panel' : ''}><Link to ={'recommendations'}>Recommendations</Link></li>
                <li className={this.props.presentationSlug ==='services_provided' ? 'active-presentation-panel' : ''}><Link to ={'services_provided'}>Services Provided</Link></li>
                {/* <li className={this.props.presentationSlug ==='investments' ? 'active-presentation-panel' : ''}><Link to ={'investments'}>Investments</Link></li> */}
                <li className={this.props.presentationSlug ==='risk_profile' ? 'active-presentation-panel' : ''}><Link to ={'risk_profile'}>Risk Profile</Link></li>
                <li className={this.props.presentationSlug ==='ongoing_advice' ? 'active-presentation-panel' : ''}><Link to ={'ongoing_advice'}>Ongoing Service and Advice Fees</Link></li>
              </ul>
						</div>

          
					</div>

      );


  }
}


function mapStateToProps(state) {
  return {
    token: state.token,
    tokenKey: state.tokenKey,
  };
}

export default connect(mapStateToProps)(PresentationSectionPanel);
